@import 'variables';

@font-face {
    font-family: 'Helvetic Neue';
    /*a name to be used later*/
    src: url('../assets/fonts/helveticaNueu/HelveticaNeueLTStdLt.otf');
    /*URL to font*/
    font-family: 'Helvetic Neue Bold';
    /*a name to be used later*/
    src: url('../assets/fonts/helveticaNueu/HelveticaNeueLTStdBd.otf');
    /*URL to font*/
}

body {
    font-family: helvetica;
    background-color: $white;
}

.zp-texto-color-principal {
    color: $main-color;
}

.zp-texto-color-secundario {
    color: $fourth-color;
}

.zp-texto-color-tercero {
    color: $secondary-color;
}

.zp-btn-color-principal {
    background-color: $main-color;
    border-color: $main-color;
    border-radius: 6px;
    color: $white;
    width: 100%;
}

.zp-btn-color-secundario {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $white;
    border-radius: 6px;
    width: 60%;
    &:hover {
        color: $white;
    }
}

.zp-card-noticia-img {
    height: 220px;
    width: 100%;
}

.zp-card-noticia-tag {
    position: absolute;
    top: 20px;
    right: 25px;
}

.zp-tag {
    font-weight: 100 !important;
    padding: 5px 10px;
    float: right;
    margin-bottom: 5px;
}

.zp-card{
    padding: 10px;
    border-radius: 6px;
    border: 0;
}

.zp-card-body {
    box-shadow: 0px 4px 0px 1px rgb(226 226 226 / 50%);
    color: $fourth-color;
}

.zp-no-link {
    color: inherit;
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.zp-texto-error {
    padding: 5px;
    border-radius: 4px;
    font-weight: 300;
    color: red;
    font-size: 15px;
}

.error-data {
    border-radius: 4px;
    font-weight: 300;
    color: red;
    font-size: 0.8rem;
}

.zp-flex-basis-auto {
    flex-basis: auto;
}

.zp-flex-1 {
    flex: 1;
}