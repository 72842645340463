@import 'base';

.zp-divider {
    border-top: 1.5px solid #70707064;
    margin-top: 0px;
    margin-bottom: 25px;
}

.footer{
    color: $fourth-color;
}

.mw-200{
    max-width: 200px;
}

.section-footer {
    background-image: url("/storage/imgs/otros/footer.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 15rem;
    margin-top: -9rem;
    color: white;
    display: flex;
    align-items: center;
}

@media (max-width: 575px) {
    .section-footer {
        background-position: right;
    }
}

@media (min-width: 576px) and (max-width: 767px){
    .section-footer {
        background-position: right;
    }
}