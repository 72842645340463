// Colors
$main-color: #ff5517;
$secondary-color: #00578f;
$secondary-color-transparency: #357399;
$third-color: #969696;
$fourth-color: #5A5A5A;
$white: #FFFFFF;
$black: #000000;
$light-gray: #F5F5F5;
$little-light-gray: #D0D0D0;
$new-blue-color:#00578f;
$letter-color: #5f5f5f;
$letter-color-light: #aaaaaa;
$primary: #ff5517;

$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

.mt-10 {
    margin-top: 6rem !important;
}

.-mt-20 {
    margin-top: -20px !important;
}

.p-card {
    padding: 15px 25px 0px 25px;
}

.letter-color {
    color: $letter-color;
}

.letter-color-light {
    color: $letter-color-light;
}

.letter-color-black {
    color: #000000;
}

.color-main-color {
    color: $main-color;
}

.bg-black {
    background-color: #000000;
    border-color: #000000;
}

.bg-main-color {
    background-color: $main-color;
    border-color: $main-color;
}

.separation-footer {
    margin-bottom: 15rem;
}

.zp-btn-primary {
    background-color: $main-color;
    border-color: $main-color;
    color: white;
    width: fit-content;
}

.zp-btn-primary:hover {
    opacity: 0.85;
    color: white;
}